import React from 'react';

import CreateListBlock from './CreateListBlock';
import styles from './ListOverview.module.scss';
import ListThumbnail from './ListThumbnails';
import ListThumbnailSkeleton from './ListThumbnails/ListThumbnailSkeleton';
import { useModal } from '@components/Modals/useModal';
import { sortLists } from 'lib/client/sortLists/sortLists';

import useGetLists from 'apis/list/hooks/useGetLists';

import { useSettings } from 'hooks/useSettings';

export function ListOverview() {
    const { showModal } = useModal();
    const { data: { lists = [] } = {}, isLoading } = useGetLists({
        select: data => ({
            lists: sortLists(data?.lists),
        }),
    });
    const { maxLists } = useSettings();

    return (
        <>
            <CreateListBlock
                disabled={lists.length >= maxLists}
                loading={isLoading}
                onCreateList={() => showModal('CreateListModal', {})}
            />

            <div className={styles.wrapper}>
                {isLoading ? (
                    <ListThumbnailSkeleton />
                ) : (
                    <>
                        {lists.map(list => (
                            <ListThumbnail key={list.listId} list={list} />
                        ))}
                    </>
                )}
            </div>
        </>
    );
}
